export default {
    name: 'Auth',

    mounted () {
        if(this.$route.query.search) this.keyword = this.$route.query.search;
        this.handleGetCategory();
    },

    methods: {
        // 获取分类
        handleGetCategory () {
            if (this.loading) return;
            this.loading = true;
            this.$Message.loading({content: '请求中...', duration: 0})
            this.$api.auth.authTmpl({}).then(res => {
                this.loading = false;
                this.$Message.destroy()
                if (res.status_code != 0) return this.$Message.warning({content: res.status_msg})
                this.catItems = res.obj.role_list;
                this.role_id = res.obj.role_list[0].role_id;
                this.handleGetData();
            }).catch( err => {
                this.loading = false;
                this.$Message.destroy()
                console.log(err)
            })

        },
        // 获取员工授权列表
        handleGetData() {
            if (this.loading) return;
            this.loading = true;
            this.$api.auth.staffAuthList({
                role_id: this.role_id,
                keyword:this.keyword
            }).then(res => {
                this.loading = false;
                if (res.status_code != 0) return this.$Message.warning({content: res.status_msg})
                this.items = res.obj.map(v => {
                    v.length = 3;
                    return v;
                });
                this.check_array = res.obj.map(v => Number(v.staff_id))
                this.isSetManage = false;
                this.isDrawer = false;

                if (this.items.length <= 0) {
                    this.$Modal.confirm({
                        title:'提示', 
                        content: '当前没有内容，需添加员工授权',
                        cancelText: '关闭',
                        okText: '添加员工',
                        onOk: () => {
                            this.$router.push({ name: 'AuthAdd' })
                        }
                    })   
                }
            }).catch( err => {
                this.loading = false;
                console.log(err)
            })
        },

        // 搜索
        handdleSearch() {
            this.$router.push({
                path:'/auth/',
                query:{
                    search:this.keyword
                }
            })
            this.handleGetData()
        },

        // 删除员工授权
        handleDelete(row, index) {
            if (this.loading) return;
            this.loading = true;
            this.$Message.loading({content: '请求中...', duration: 0})
            this.$api.auth.staffUnauth({
                auth_ids: [row.auth_id]
            }).then(res => {
                this.loading = false;
                this.$Message.destroy()
                if (res.status_code != 0) return this.$Message.warning({content: res.status_msg})
                this.$Message.success({content: '删除成功'})
                this.items.splice(index, 1);
            }).catch( err => {
                this.loading = false;
                this.$Message.destroy()
                console.log(err)
            })
        } 
    },

    data () {
        return {
            loading: false,
            keyword: '',
            role_id: 0,
            catItems: [],

            // 设置员工/部门
            check_array: [],
            select_array: [],
            select_name: '',
            isSetManage: false,

            items: [],
            columns: [
                {
                    title: '序号',
                    slot: 'key',
                    align: 'center',
                    width: 100
                },
                {
                    title: '员工名称',
                    key: 'staff_name',
                    width: 140
                },
                {
                    title: '功能授权',
                    slot: 'auth_item',
                    minWidth: 300
                },
                {
                    title: '授权时间',
                    key: 'auth_date',
                    width: 220
                },
                {
                    title: '操作',
                    slot: 'action',
                    width: 180
                }
            ],

            departmentItems: [],
            departmentItem: {},

            roleId: 0,
            roleItem: {},
            roleItems: [],
        }
    }
}